const AuthRoutes = {};

const NonAuthRoutes = {
  landingPage: "/",
  ourStory: "/our-story",
  play: "/play",
  eat: "/eat",
  contact: "/contact",
};

export { AuthRoutes, NonAuthRoutes };
