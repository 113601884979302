/* eslint-disable no-nested-ternary */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NonAuthRoutes } from "../../helpers/urls";
import { ReactComponent as SkateCityLogo } from "../../assets/svg/skate-city-logo.svg";
import { ReactComponent as ClockIcon } from "../../assets/svg/clock-icon.svg";
import { ReactComponent as LocationIcon } from "../../assets/svg/location-icon.svg";
import { ReactComponent as PhoneIcon } from "../../assets/svg/phone-icon.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/svg/whatsapp-icon.svg";
import { ReactComponent as TwitterIcon } from "../../assets/svg/twitter-icon.svg";
import { ReactComponent as FacebookIcon } from "../../assets/svg/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/svg/instagram-icon.svg";
import { ReactComponent as MenuBarIcon } from "../../assets/svg/menu-bar-icon.svg";
import { useWindowResize } from "../../helpers/useWindowResize";

function HeaderDesktopContactDetails({
  icon,
  topText,
  addressTextLink,
  bottomText1,
  bottomText2,
  phoneNumber,
  specificStyle,
}) {
  return (
    <div className={`${specificStyle} flex items-center justify-between`}>
      <div>{icon}</div>
      <div>
        <div>
          <p className="font-PoppinsLight text-sm">{topText}</p>
        </div>
        <div className="flex items-center text-skatecityng-purple">
          <a
            href="tel:+2348099473333"
            className="whitespace-nowrap font-PoppinsSemiBold text-lg"
          >
            {phoneNumber}
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://goo.gl/maps/8xXhe7cYeeGRCgQc8"
            className="whitespace-nowrap font-PoppinsSemiBold text-lg"
          >
            {addressTextLink}
          </a>
          <p className="whitespace-nowrap font-PoppinsSemiBold text-lg mr-2">
            {bottomText1}
          </p>

          <p className="whitespace-nowrap font-PoppinsSemiBold text-sm">
            {bottomText2}
          </p>
        </div>
      </div>
    </div>
  );
}

function HeaderMobileContactDetails({
  icon,
  topText,
  addressTextLink,
  bottomText1,
  bottomText2,
  phoneNumber,
  title,
  specificStyle,
}) {
  return (
    <div title={title} className="flex items-center cursor-pointer">
      <div>{icon}</div>
      <div className={`${specificStyle} -ml-[0px]`}>
        <div>
          <p className="whitespace-nowrap font-PoppinsLight text-[8px]">
            {topText}
          </p>
        </div>
        <div className="flex items-center text-skatecityng-purple">
          <a
            href="tel:+2348099473333"
            className="whitespace-nowrap font-PoppinsBold text-[8.5px]"
          >
            {phoneNumber}
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://goo.gl/maps/8xXhe7cYeeGRCgQc8"
            className="whitespace-nowrap font-PoppinsBold text-[8.5px]"
          >
            {addressTextLink}
          </a>
          <p className="whitespace-nowrap font-PoppinsBold text-[8.5px] mr-[2px]">
            {bottomText1}
          </p>

          <p className="whitespace-nowrap font-PoppinsBold text-[8.5px]">
            {bottomText2}
          </p>
        </div>
      </div>
    </div>
  );
}

function Header({ setMobileMenuOpen }) {
  const location = useLocation();
  const { width } = useWindowResize();
  const navigate = useNavigate();

  /** Diplay Header On Desktop View */
  const headerDesktopView = () => {
    return (
      <div>
        <div className="h-[83px] px-20 flex items-center justify-between">
          <button
            onClick={() => navigate(NonAuthRoutes.landingPage)}
            type="button"
          >
            <SkateCityLogo title="Skate City" className="h-14" />
          </button>
          <div className="flex items-center justify-between">
            <HeaderDesktopContactDetails
              icon={<ClockIcon className="h-10 mr-5" />}
              topText="Opening times"
              bottomText1="9am - 9pm"
              bottomText2="(Mon - Sun)"
              specificStyle="mr-20"
            />
            <HeaderDesktopContactDetails
              icon={<LocationIcon className="h-10 mr-5" />}
              topText="Visit us"
              addressTextLink="Maryland Mall, Lagos NG"
              specificStyle="mr-20"
            />
            <HeaderDesktopContactDetails
              icon={<PhoneIcon className="h-10 mr-5" />}
              topText="Call us"
              phoneNumber="0809 947 3333"
            />
          </div>
        </div>

        <div className="h-[72px] px-20 flex items-center justify-between bg-skatecityng-purple">
          <div>
            <nav className="font-PoppinsRegular text-xl text-white text-center">
              <ol className="flex items-center justify-between">
                {location.pathname === NonAuthRoutes.landingPage ? (
                  <li>
                    <button
                      type="button"
                      title="Home"
                      onClick={() => navigate(NonAuthRoutes.landingPage)}
                      className="mr-12 px-6 py-3 bg-white font-PoppinsBold text-skatecityng-purple rounded-[100px]"
                    >
                      Home
                    </button>
                  </li>
                ) : (
                  <li>
                    <button
                      type="button"
                      title="Home"
                      className="mr-12"
                      onClick={() => navigate(NonAuthRoutes.landingPage)}
                    >
                      Home
                    </button>
                  </li>
                )}

                {location.pathname === NonAuthRoutes.ourStory ? (
                  <li>
                    <button
                      type="button"
                      title="Our Story"
                      onClick={() => navigate(NonAuthRoutes.ourStory)}
                      className="mr-12  whitespace-nowrap px-6 py-3 bg-white font-PoppinsBold text-skatecityng-purple rounded-[100px]"
                    >
                      Our Story
                    </button>
                  </li>
                ) : (
                  <li>
                    <button
                      type="button"
                      title="Our Story"
                      className="mr-12 whitespace-nowrap"
                      onClick={() => navigate(NonAuthRoutes.ourStory)}
                    >
                      Our Story
                    </button>
                  </li>
                )}

                {location.pathname === NonAuthRoutes.play ? (
                  <li>
                    <button
                      type="button"
                      title="Play"
                      onClick={() => navigate(NonAuthRoutes.play)}
                      className="mr-12  px-6 py-3 bg-white font-PoppinsBold text-skatecityng-purple rounded-[100px]"
                    >
                      Play
                    </button>
                  </li>
                ) : (
                  <li>
                    <button
                      type="button"
                      title="Play"
                      className="mr-12"
                      onClick={() => navigate(NonAuthRoutes.play)}
                    >
                      Play
                    </button>
                  </li>
                )}

                {location.pathname === NonAuthRoutes.eat ? (
                  <li>
                    <button
                      type="button"
                      title="Eat"
                      onClick={() => navigate(NonAuthRoutes.eat)}
                      className="mr-12  px-6 py-3 bg-white font-PoppinsBold text-skatecityng-purple rounded-[100px]"
                    >
                      Eat
                    </button>
                  </li>
                ) : (
                  <li>
                    <button
                      type="button"
                      title="Eat"
                      className="mr-12"
                      onClick={() => navigate(NonAuthRoutes.eat)}
                    >
                      Eat
                    </button>
                  </li>
                )}

                {location.pathname === NonAuthRoutes.contact ? (
                  <li>
                    <button
                      type="button"
                      title="Contact"
                      onClick={() => navigate(NonAuthRoutes.contact)}
                      className="mr-12  px-6 py-3 bg-white font-PoppinsBold text-skatecityng-purple rounded-[100px]"
                    >
                      Contact
                    </button>
                  </li>
                ) : (
                  <li>
                    <button
                      type="button"
                      title="Contact"
                      className="mr-12"
                      onClick={() => navigate(NonAuthRoutes.contact)}
                    >
                      Contact
                    </button>
                  </li>
                )}
              </ol>
            </nav>
          </div>
          <div className="flex items-center justify-between ">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://wa.me/message/SGYATGRDO2FGB1"
            >
              <WhatsappIcon
                title="Follow on Twitter"
                className="h-8 mr-10 cursor-pointer"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/skatecity_ng"
            >
              <InstagramIcon
                title="Follow on Instagram"
                className="h-8 mr-10 cursor-pointer"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/skatecityngn"
            >
              <FacebookIcon
                title="Follow on Facebook"
                className="h-8 mr-10 cursor-pointer"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.twitter.com/skatecity_ng"
            >
              <TwitterIcon
                title="Follow on Twitter"
                className="h-8 cursor-pointer"
              />
            </a>
          </div>
        </div>
      </div>
    );
  };

  /** Diplay Header On Mobile View */
  const headerMobileView = () => {
    return (
      <div>
        <div className="flex items-center justify-center bg-skatecityng-background-white">
          <button
            type="button"
            className="h-14 mt-6 mb-1"
            onClick={() => navigate(NonAuthRoutes.landingPage)}
          >
            <SkateCityLogo title="Skate City" />
          </button>
        </div>
        <div className="flex items-center justify-evenly my-3 w-full bg-[#f8fdff]">
          <HeaderMobileContactDetails
            icon={<ClockIcon className="h-4 -ml-2" />}
            topText="Opening times"
            bottomText1="9am - 9pm"
            bottomText2="(Mon - Sun)"
            title="Opening times"
            specificStyle="-ml-[5px]"
          />
          <HeaderMobileContactDetails
            icon={<LocationIcon className="h-4 -ml-1" />}
            topText="Visit us"
            addressTextLink="Maryland Mall, Lagos NG"
            title="Visit us"
            specificStyle="-ml-[5px]"
          />
          <HeaderMobileContactDetails
            icon={<PhoneIcon className="h-4 -ml-1" />}
            topText="Call us"
            phoneNumber="0809 947 3333"
            title="Call us"
          />
        </div>

        <div className="h-[38px] px-3 flex items-center justify-between bg-skatecityng-purple">
          <div className="flex items-center justify-between">
            <button
              type="button"
              title="Close"
              className="mr-2 p-1 cursor-pointer"
              onClick={() => setMobileMenuOpen(true)}
            >
              <MenuBarIcon title="Nenu" className="h-4" />
            </button>
            <p className="text-white text-sm">
              {location.pathname === NonAuthRoutes.landingPage
                ? "Home"
                : location.pathname === NonAuthRoutes.ourStory
                ? "Our Story"
                : location.pathname === NonAuthRoutes.play
                ? "Play"
                : location.pathname === NonAuthRoutes.eat
                ? "Eat"
                : location.pathname === NonAuthRoutes.contact
                ? "Contact"
                : "Home"}
            </p>
          </div>
          <div className="flex items-center justify-between">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://wa.me/message/SGYATGRDO2FGB1"
            >
              <WhatsappIcon
                title="Follow on Twitter"
                className="h-4 mr-2 cursor-pointer"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/skatecity_ng"
            >
              <InstagramIcon
                title="Follow on Instagram"
                className="h-4  mr-2 cursor-pointer"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/skatecityngn"
            >
              <FacebookIcon
                title="Follow on Facebook"
                className="h-4 mr-2 cursor-pointer"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.twitter.com/skatecity_ng"
            >
              <TwitterIcon
                title="Follow on Twitter"
                className="h-4 cursor-pointer"
              />
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="sticky top-0 z-30 bg-skatecityng-background-white">
      {width > 1060 ? headerDesktopView() : headerMobileView()}
    </div>
  );
}

export default Header;
