import React from "react";
import { useNavigate } from "react-router-dom";
import { NonAuthRoutes } from "../../helpers/urls";
import { useWindowResize } from "../../helpers/useWindowResize";
import FoodDrinksImage from "../../assets/img/FoodDrinksImage.png";
import EventsImage from "../../assets/img/EventsImage.png";
import ShopImage from "../../assets/img/ShopImage.png";
import { ReactComponent as ArrowRightWhite } from "../../assets/svg/arrow-right-white.svg";

function ActivitiesDesktopReusableComponent({
  image,
  cardName,
  cardDetails,
  isClick,
  buttonText,
  specificStyle,
  isOutsideLinkClick,
}) {
  return (
    <div className={`${specificStyle} flex items-center justify-start`}>
      <div className="h-[300px] mb-5 mr-6 rounded-lg">{image}</div>
      <div className="w-1/2">
        <p className="mb-6  text-skatecityng-font-black font-Spartan text-[20px] leading-[25px]">
          {cardName}
        </p>
        <p className="mb-6  font-PoppinsRegular text-[20px] leading-[32px]">
          {cardDetails}
        </p>
        <div className="flex items-center">
          <div>
            {isOutsideLinkClick ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://purple.shop/seller/skatecity"
              >
                <button
                  type="button"
                  title={buttonText}
                  className=" hover:bg-[#565bbf] px-6 h-[50px] bg-skatecityng-purple rounded-[100px] flex items-center justify-center"
                >
                  <p className="text-white mr-2">{buttonText}</p>
                  <ArrowRightWhite />
                </button>
              </a>
            ) : (
              <button
                type="button"
                title={buttonText}
                onClick={isClick}
                className=" hover:bg-[#565bbf] px-6 h-[50px] bg-skatecityng-purple rounded-[100px] flex items-center justify-center"
              >
                <p className="text-white mr-2">{buttonText}</p>
                <ArrowRightWhite />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function ActivitiesMobileReusableComponent({
  image,
  cardName,
  cardDetails,
  isClick,
  buttonText,
  specificStyle,
  isOutsideLinkClick,
}) {
  return (
    <div className={`${specificStyle} w-full flex items-center justify-center`}>
      <div>
        <div className="h-[272px] mb-5 rounded-lg flex items-center justify-center">
          {image}
        </div>
        <p className="my-6 text-skatecityng-font-black font-Spartan text-[14px] leading-[23px]">
          {cardName}
        </p>
        <p className="mb-6  font-PoppinsRegular text-[14px] leading-[23px]">
          {cardDetails}
        </p>
        <div className="flex items-center">
          <div>
            {isOutsideLinkClick ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://purple.shop/seller/skatecity"
              >
                <button
                  type="button"
                  title={buttonText}
                  className=" hover:bg-[#565bbf] px-6 h-[50px] bg-skatecityng-purple rounded-[100px] flex items-center justify-center"
                >
                  <p className="text-white mr-2 text-[13px] leading-[26px]">
                    {buttonText}
                  </p>
                  <ArrowRightWhite />
                </button>
              </a>
            ) : (
              <button
                type="button"
                title={buttonText}
                onClick={isClick}
                className=" hover:bg-[#565bbf] px-6 h-[50px] bg-skatecityng-purple rounded-[100px] flex items-center justify-center"
              >
                <p className="text-white mr-2 text-[13px] leading-[26px]">
                  {buttonText}
                </p>
                <ArrowRightWhite />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function Section4() {
  const { width } = useWindowResize();
  const navigate = useNavigate();

  return (
    <div>
      {width > 1060 ? (
        <div className="w-full pt-[100px]">
          <div className="px-20">
            <div>
              <p className="mb-6 text-center text-skatecityng-font-black font-Spartan text-[32px] leading-[40px]">
                Activities - We&apos;re all about fun for everyone!
              </p>
              <p className="mb-6 text-center font-PoppinsRegular text-[20px] leading-[32px] px-[160px]">
                Skating, gaming, eating, have your events and shop merchandise
                at SkateCity. So why wait? Join us today and experience the
                thrill of SkateCity, we provide it all, come roll with it!
              </p>
            </div>
            <div className="mt-[64px]">
              <ActivitiesDesktopReusableComponent
                image={<img src={FoodDrinksImage} alt="Playstation 4" />}
                cardName="Food & Drinks -  Grills & Euphoria"
                cardDetails="A variety of tasty snacks, grills and refreshments for gamers to enjoy during their gaming session, providing a convenient and satisfying option for all visitors."
                buttonText="Visit Grills & Euphoria"
                isClick={() => navigate(NonAuthRoutes.eat)}
                specificStyle=""
              />
              <ActivitiesDesktopReusableComponent
                image={<img src={EventsImage} alt="Playstation 4" />}
                cardName="Events"
                cardDetails="A fun and unique way to celebrate special occasions. Whether it's a birthday party or a school trip, Skate City partners with Grills & Euphoria to offer a versatile and exciting venue with an optional on-site catering service."
                buttonText="Learn more about events"
                click={() => navigate(NonAuthRoutes.eat)}
                isClick={() =>
                  navigate(NonAuthRoutes.play, {
                    state: { sectionName: "events" },
                  })
                }
                specificStyle=""
              />
              <ActivitiesDesktopReusableComponent
                image={<img src={ShopImage} alt="Playstation 4" />}
                cardName="Shop"
                cardDetails="An opportunity for visitors to purchase gear, equipment, and accessories and souvenirs related to the sport – allowing them to show their support and remember their experience."
                buttonText="SkateCity merchandise"
                specificStyle=""
                isOutsideLinkClick
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full pt-[100px]">
          <div className="px-2">
            <div>
              <p className="mb-6 text-center text-skatecityng-font-black font-Spartan text-[20px] leading-[25px] px-[10px]">
                Activities - We&apos;re all about fun for everyone!
              </p>
              <p className="mb-6 text-center text-skatecityng-font-black font-PoppinsRegular text-[14px] leading-[23px] px-[30px]">
                Skating, gaming, eating, have your events and shop merchandise
                at SkateCity. So why wait? Join us today and experience the
                thrill of SkateCity, we provide it all, come roll with it!
              </p>
            </div>
            <div className="mt-[64px] mx-6">
              <ActivitiesMobileReusableComponent
                image={<img src={FoodDrinksImage} alt="Food and Drinks" />}
                cardName="Food & Drinks -  Grills & Euphoria"
                cardDetails="A variety of tasty snacks, grills and refreshments for gamers to enjoy during their gaming session, providing a convenient and satisfying option for all visitors."
                buttonText="Visit Grills & Euphoria"
                isClick={() => navigate(NonAuthRoutes.eat)}
                specificStyle=""
              />
              <ActivitiesMobileReusableComponent
                image={<img src={EventsImage} alt="Events" />}
                cardName="Events"
                cardDetails="A fun and unique way to celebrate special occasions. Whether it's a birthday party or a school trip, Skate City partners with Grills & Euphoria to offer a versatile and exciting venue with an optional on-site catering service."
                buttonText="Learn more about events"
                isClick={() =>
                  navigate(NonAuthRoutes.play, {
                    state: { sectionName: "events" },
                  })
                }
                specificStyle="mt-[64px]"
              />
              <ActivitiesMobileReusableComponent
                image={<img src={ShopImage} alt="Shop" />}
                cardName="Shop"
                cardDetails="An opportunity for visitors to purchase gear, equipment, and accessories and souvenirs related to the sport – allowing them to show their support and remember their experience."
                buttonText="SkateCity merchandise"
                specificStyle="mt-[64px]"
                isOutsideLinkClick
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Section4;
