/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useWindowResize } from "../../helpers/useWindowResize";
import { ReactComponent as CancelIconRed } from "../../assets/svg/cancel-icon-red.svg";
import SkateCityLogoLarge from "../../assets/img/skate-city-logo-large.png";

function EmailResponse({ setEmailResponseOpen, isErrorResponse }) {
  const { width } = useWindowResize();

  return (
    <div>
      {width > 1060 ? (
        <div>
          <div className="fixed z-50 outline-none focus:outline-none">
            <div className="flex items-center justify-center w-screen h-screen">
              <div className="relative w-[960px] py-[48px] rounded-lg bg-white overflow-auto  outline-none focus:outline-none">
                <div className="flex items-center justify-between w-full h-full px-[40px]">
                  <div className="w-[600px]">
                    <p className="text-[#4395D5] mb-1 font-PoppinsBold text-[20px] leading-[30px]">
                      {isErrorResponse ? "Oops!" : "Welcome to our"}
                    </p>
                    {isErrorResponse ? (
                      <p className="text-[#E00305] mb-1 font-PoppinsBold text-[40px] leading-[60px]">
                        Something went wrong!
                      </p>
                    ) : (
                      <p className="text-skatecityng-purple mb-1 font-PoppinsBold text-[40px] leading-[60px]">
                        Skatecity community!
                      </p>
                    )}

                    <p className="text-skatecityng-font-black mt-[30px] mb-4 font-PoppinsRegular text-[16px] leading-[26px]">
                      {isErrorResponse
                        ? "This email may be invalid or already exists in our database, please check the email and try again. "
                        : "Thank you for signing up for our mailing service. By subscribing, you'll be the first to know about our skating events, exclusive discounts and updates on latest games and other activities."}
                    </p>
                    <p className="text-skatecityng-font-black mb-[30px] font-PoppinsRegular text-[16px] leading-[26px]">
                      {isErrorResponse
                        ? ""
                        : "Do not forget to add our email address to your contacts list to ensure you receive our emails. If you ever have any questions or feedback, please don't hesitate to reach out."}
                    </p>
                    <p className="text-[#4395D5] font-PoppinsBold text-[15px] leading-[24px]">
                      {isErrorResponse
                        ? "We can't wait to share updates with you!"
                        : " We are thrilled to have you on board and can't wait to share updates with you!"}
                    </p>
                    <button
                      type="button"
                      title="Close"
                      onClick={() => setEmailResponseOpen(false)}
                      className=" w-[97px] h-[42px] bg-[#FCE6E6] rounded-[100px] mt-[30px] flex items-center justify-center border border-[#E00305]"
                    >
                      <CancelIconRed />
                      <p className="text-[#E00305] ml-2 font-PoppinsBold text-[13px] leading-[26px]">
                        Close
                      </p>
                    </button>
                  </div>
                  <div className="">
                    <img src={SkateCityLogoLarge} alt="Skate City Logo Large" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => setEmailResponseOpen(false)}
            className="opacity-80 fixed inset-0 z-40 bg-black"
          />
        </div>
      ) : (
        <div>
          <div className="fixed z-50 outline-none focus:outline-none">
            <div className="flex items-center justify-center w-screen h-screen px-6">
              <div className="relative w-[428px] h-[658px] p-4 rounded-lg bg-white overflow-auto  outline-none focus:outline-none">
                <div className="">
                  <div className="flex items-center justify-center mb-[30px]">
                    <img
                      src={SkateCityLogoLarge}
                      alt="Skate City Logo Large"
                      className="w-[125px] h-[165px]"
                    />
                  </div>
                  <div className="">
                    <p className="text-[#4395D5] mb-1 font-PoppinsBold text-[14px] leading-[21px]">
                      {isErrorResponse ? "Oops!" : "Welcome to our"}
                    </p>
                    {isErrorResponse ? (
                      <p className="text-[#E00305] mb-1 font-PoppinsBold text-[20px] leading-[30px]">
                        Something went wrong!
                      </p>
                    ) : (
                      <p className="text-skatecityng-purple mb-1 font-PoppinsBold text-[20px] leading-[30px]">
                        Skatecity community!
                      </p>
                    )}
                    <p className="text-skatecityng-font-black mt-[30px] mb-4 font-PoppinsRegular text-[12px] leading-[20px]">
                      {isErrorResponse
                        ? "This email may be invalid or already exists in our database, please check the email and try again. "
                        : "Thank you for signing up for our mailing service. By subscribing, you'll be the first to know about our skating events, exclusive discounts and updates on latest games and other activities."}
                    </p>
                    <p className="text-skatecityng-font-black mb-[30px] font-PoppinsRegular text-[12px] leading-[20px]">
                      {isErrorResponse
                        ? ""
                        : "Do not forget to add our email address to your contacts list to ensure you receive our emails. If you ever have any questions or feedback, please don't hesitate to reach out."}
                    </p>
                    <p className="text-[#4395D5] font-PoppinsBold text-[12px] leading-[20px]">
                      {isErrorResponse
                        ? "We can't wait to share updates with you!"
                        : " We are thrilled to have you on board and can't wait to share updates with you!"}
                    </p>
                    <button
                      type="button"
                      title="Close"
                      onClick={() => setEmailResponseOpen(false)}
                      className=" w-[97px] h-[42px] bg-[#FCE6E6] rounded-[100px] mt-[30px] flex items-center justify-center border border-[#E00305]"
                    >
                      <CancelIconRed />
                      <p className="text-[#E00305] ml-2 font-PoppinsRegular text-[13px] leading-[26px]">
                        Close
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => setEmailResponseOpen(false)}
            className="opacity-80 fixed inset-0 z-40 bg-black"
          />
        </div>
      )}
    </div>
  );
}

export default EmailResponse;
