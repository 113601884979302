/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowResize } from "../../helpers/useWindowResize";
import { NonAuthRoutes } from "../../helpers/urls";
import { ReactComponent as CancelIconPurple } from "../../assets/svg/cancel-icon-purple.svg";
import { ReactComponent as ArrowRightBlue } from "../../assets/svg/arrow-right-blue.svg";

function MobileMenu({ setMobileMenuOpen }) {
  const location = useLocation();
  const { width } = useWindowResize();
  const navigate = useNavigate();

  return (
    <div>
      {width < 1060 && (
        <div>
          <div className="fixed z-50 outline-none focus:outline-none">
            <div className="flex items-center justify-center w-screen h-screen px-6">
              <div className="relative h-[471px] w-[428px] rounded-lg pt-[10px] bg-[#D2D7E9] overflow-auto  outline-none focus:outline-none">
                <div className="flex items-center justify-end mr-[30px] p-4">
                  <button
                    type="button"
                    title="Close"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <CancelIconPurple className="cursor-pointer " />
                  </button>
                </div>
                <nav className="pl-[30px] text-skatecityng-purple font-PoppinsRegular text-[16px] leading-[22.4px] w-[90%]">
                  <ol className="">
                    {location.pathname === NonAuthRoutes.landingPage ? (
                      <li>
                        <button
                          type="button"
                          title="Home"
                          onClick={() => navigate(NonAuthRoutes.landingPage)}
                          className="mb-2 px-6 py-3 text-white bg-skatecityng-purple font-PoppinsBold w-full text-left rounded-[100px]"
                        >
                          Home
                        </button>
                      </li>
                    ) : (
                      <li>
                        <button
                          type="button"
                          title="Home"
                          className="mb-3 px-6 py-3 text-left w-full hover:text-white hover:bg-skatecityng-purple rounded-[100px]"
                          onClick={() => navigate(NonAuthRoutes.landingPage)}
                        >
                          Home
                        </button>
                      </li>
                    )}

                    {location.pathname === NonAuthRoutes.ourStory ? (
                      <li>
                        <button
                          type="button"
                          title="Our Story"
                          onClick={() => navigate(NonAuthRoutes.ourStory)}
                          className="whitespace-nowrap mb-2 px-6 py-3 text-white bg-skatecityng-purple font-PoppinsBold w-full text-left rounded-[100px]"
                        >
                          Our Story
                        </button>
                      </li>
                    ) : (
                      <li>
                        <button
                          type="button"
                          title="Our Story"
                          className="whitespace-nowrap mb-3 px-6 py-3 text-left w-full hover:text-white hover:bg-skatecityng-purple rounded-[100px]"
                          onClick={() => navigate(NonAuthRoutes.ourStory)}
                        >
                          Our Story
                        </button>
                      </li>
                    )}

                    {location.pathname === NonAuthRoutes.play ? (
                      <li>
                        <button
                          type="button"
                          title="Play"
                          onClick={() => navigate(NonAuthRoutes.play)}
                          className="mb-2 px-6 py-3 text-white bg-skatecityng-purple font-PoppinsBold w-full text-left rounded-[100px]"
                        >
                          Play
                        </button>
                      </li>
                    ) : (
                      <li>
                        <button
                          type="button"
                          title="Play"
                          className="mb-3 px-6 py-3 text-left w-full hover:text-white hover:bg-skatecityng-purple rounded-[100px]"
                          onClick={() => navigate(NonAuthRoutes.play)}
                        >
                          Play
                        </button>
                      </li>
                    )}

                    {location.pathname === NonAuthRoutes.eat ? (
                      <li>
                        <button
                          type="button"
                          title="Eat"
                          onClick={() => navigate(NonAuthRoutes.eat)}
                          className="mb-2 px-6 py-3 text-white bg-skatecityng-purple font-PoppinsBold w-full text-left rounded-[100px]"
                        >
                          Eat
                        </button>
                      </li>
                    ) : (
                      <li>
                        <button
                          type="button"
                          title="Eat"
                          className="mb-3 px-6 py-3 text-left w-full hover:text-white hover:bg-skatecityng-purple rounded-[100px]"
                          onClick={() => navigate(NonAuthRoutes.eat)}
                        >
                          Eat
                        </button>
                      </li>
                    )}

                    {location.pathname === NonAuthRoutes.contact ? (
                      <li>
                        <button
                          type="button"
                          title="Contact"
                          onClick={() => navigate(NonAuthRoutes.contact)}
                          className="mb-2 px-6 py-3 text-white bg-skatecityng-purple font-PoppinsBold w-full text-left rounded-[100px]"
                        >
                          Contact
                        </button>
                      </li>
                    ) : (
                      <li>
                        <button
                          type="button"
                          title="Contact"
                          className="mb-3 px-6 py-3 text-left w-full hover:text-white hover:bg-skatecityng-purple rounded-[100px]"
                          onClick={() => navigate(NonAuthRoutes.contact)}
                        >
                          Contact
                        </button>
                      </li>
                    )}
                  </ol>
                </nav>
                <div className="absolute bottom-0 w-full h-[105px] bg-[#F5E723] flex items-center justify-center rounded-lg">
                  <div>
                    <p className="text-[#4395D5] mb-1 font-PoppinsRegular text-[11.59px] leading-[21.4px] text-center">
                      Enjoy bundle discounts & more with
                    </p>
                    <p className="text-skatecityng-purple mb-1 font-PoppinsBold text-[17.83px] leading-[21.4px] text-center">
                      Skatecity membership plans
                    </p>
                    <div className="flex items-center justify-center">
                      <button type="button" title="Skatecity membership plans">
                        <ArrowRightBlue className="cursor-pointer " />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => setMobileMenuOpen(false)}
            className="opacity-80 fixed inset-0 z-40 bg-black"
          />
        </div>
      )}
    </div>
  );
}

export default MobileMenu;
