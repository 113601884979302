import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import EmailSubscribe from "../../components/emailSubscribe/emailSubscribe";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Section5 from "./section5";
import MobileMenu from "../../components/mobileMenu/mobileMenu";
import EmailResponse from "../../components/emailResponse/emailResponse";

function LandingPage() {
  const ref = useRef(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [emailResponseOpen, setEmailResponseOpen] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(false);

  useEffect(() => {
    const ac = new AbortController();
    document.title = "Skate City • Home";
    window.scrollTo({ top: 0, behavior: "auto" });
    return function cleanup() {
      ac.abort();
    };
  }, []);

  return (
    <div>
      {mobileMenuOpen && <MobileMenu setMobileMenuOpen={setMobileMenuOpen} />}
      {emailResponseOpen && (
        <EmailResponse
          setEmailResponseOpen={setEmailResponseOpen}
          isErrorResponse={isErrorResponse}
        />
      )}
      <Header setMobileMenuOpen={setMobileMenuOpen} />
      <section>
        <Section1 scrollDownRef={ref} />
      </section>
      <section ref={ref}>
        <Section2 />
      </section>
      <section>
        <Section3 />
      </section>
      <section>
        <Section4 />
      </section>
      <section>
        <EmailSubscribe
          setEmailResponseOpen={setEmailResponseOpen}
          setIsErrorResponse={setIsErrorResponse}
        />
      </section>
      <section>
        <Section5 />
      </section>
      <Footer />
    </div>
  );
}

export default LandingPage;
