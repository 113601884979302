import React from "react";

function Inputs({
  specificInputLabel,
  specificInputTitle,
  specificInputType,
  specificInputPlaceholder,
  specificInputValue,
  specificInputOnChange,
  specificInputStyling,
  specificTitleStyling,
}) {
  return (
    <div>
      <label htmlFor={specificInputLabel}>
        {specificInputTitle && (
          <p
            className={`${specificTitleStyling} mb-1 font-PoppinsRegular text-[13px] leading-[19.5px] text-skatecityng-font-black`}
          >
            {specificInputTitle}
          </p>
        )}
        <input
          id={specificInputLabel}
          type={specificInputType}
          value={specificInputValue}
          placeholder={specificInputPlaceholder}
          onChange={specificInputOnChange}
          className={`${specificInputStyling} text-[14px] leading-[22px] py-3 px-5 appearance-none focus:outline-none`}
        />
      </label>
    </div>
  );
}

export default Inputs;
