import React from "react";
import { useNavigate } from "react-router-dom";
import { NonAuthRoutes } from "../../helpers/urls";
import { ReactComponent as BlueSliceDesktop } from "../../assets/svg/blue-slice-desktop.svg";
import { ReactComponent as ArrowRightPurple } from "../../assets/svg/arrow-right-purple.svg";
import { ReactComponent as ArrowDownPurple } from "../../assets/svg/arrow-down-purple.svg";
import RollerSkateShoeBlack from "../../assets/img/roller-skate-shoe-black.png";
import { useWindowResize } from "../../helpers/useWindowResize";

function Section1({ scrollDownRef }) {
  const { width } = useWindowResize();
  const navigate = useNavigate();

  /** handles scroll to section */
  const handleScroll = () => {
    const barHeight = "120px";
    // eslint-disable-next-line no-param-reassign
    scrollDownRef.current.style.scrollMargin = barHeight;
    scrollDownRef.current.scrollIntoView({ behavior: "smooth" });
  };

  /** handles scroll to section */
  const handleScrollMobile = () => {
    const barHeight = "200px";
    // eslint-disable-next-line no-param-reassign
    scrollDownRef.current.style.scrollMargin = barHeight;
    scrollDownRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      {width > 1060 ? (
        <div>
          <div className="absolute w-full z-20 pt-8">
            <div className="flex items-center justify-between ">
              <div className="ml-20 w-[523px]">
                <p className="font-PoppinsRegular text-[40px]  leading-[60px] text-skatecityng-font-black">
                  Get ready to experience the ultimate thrill on wheels at{" "}
                  <span className="font-PoppinsBold text-skatecityng-purple">
                    SkateCity
                  </span>
                </p>
                <div>
                  <p className="mt-10  font-PoppinsRegular text-[13px] leading-[26px] text-skatecityng-font-black">
                    AGE 4 AND ABOVE?{" "}
                    <span className="font-PoppinsBold rainbow-gradient-text">
                      COME ROLL WITH IT!
                    </span>
                  </p>
                </div>
                <button
                  type="button"
                  title="See all activities"
                  onClick={() =>
                    navigate(NonAuthRoutes.play, {
                      state: { sectionName: "activities" },
                    })
                  }
                  className="hover:bg-white w-[210px] h-[50px] bg-[#D3D7E9] rounded-[100px] mt-4 flex items-center justify-center border border-skatecityng-purple"
                >
                  <p className="text-skatecityng-purple mr-2">
                    See all activities
                  </p>
                  <ArrowRightPurple />
                </button>
              </div>
              <div>
                <img src={RollerSkateShoeBlack} alt="Roller Skate Shoe Black" />
              </div>
            </div>

            <div className="flex items-center justify-center">
              <button
                type="button"
                title="Scroll down"
                onClick={() => handleScroll()}
                className="hover:bg-white w-[46px] h-[66px] bg-[#D3D7E9] rounded-[100px] flex items-center justify-center border border-skatecityng-purple"
              >
                <ArrowDownPurple />
              </button>
            </div>
          </div>
          <BlueSliceDesktop className="w-screen h-full pb-[180px] bg-cover relative bg-repeat" />
        </div>
      ) : (
        <div className="w-full">
          <div className="absolute w-full mb-10 z-20">
            <div className="flex items-center justify-center">
              <img alt="Roller Skate Shoe Black" src={RollerSkateShoeBlack} />
            </div>

            <div className="flex items-center justify-center ml-3 mr-3">
              <div className="w-[380px]">
                <p className="font-PoppinsRegular text-[30px] leading-[45px] text-skatecityng-font-black">
                  Get ready to experience the ultimate thrill on wheels at{" "}
                  <span className="font-PoppinsBold text-skatecityng-purple">
                    SkateCity
                  </span>
                </p>
                <div>
                  <p className="mt-5 font-PoppinsRegular text-[13px] leading-[26px] text-skatecityng-font-black">
                    AGE 4 AND ABOVE?{" "}
                    <span className="font-PoppinsBold rainbow-gradient-text">
                      COME ROLL WITH IT!
                    </span>
                  </p>
                </div>
                <button
                  type="button"
                  title="See all activities"
                  onClick={() =>
                    navigate(NonAuthRoutes.play, {
                      state: { sectionName: "activities" },
                    })
                  }
                  className="w-[154px] h-[40px] bg-[#D3D7E9] rounded-[100px] mt-4 flex items-center justify-center border border-skatecityng-purple"
                >
                  <p className="text-skatecityng-purple text-xs mr-2">
                    See all activities
                  </p>
                  <ArrowRightPurple />
                </button>
              </div>
            </div>

            <div className="flex items-center justify-center mt-[84px] mb-[100px]">
              <button
                type="button"
                title="Scroll down"
                onClick={() => handleScrollMobile()}
                className=" w-[35px] h-[50px] bg-[#D3D7E9] rounded-[100px] mt-4 flex items-center justify-center border border-skatecityng-purple"
              >
                <ArrowDownPurple />
              </button>
            </div>
          </div>

          <BlueSliceDesktop className="w-screen h-full pb-[580px] bg-cover relative bg-repeat" />
        </div>
      )}
    </div>
  );
}

export default Section1;
