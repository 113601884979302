/* eslint-disable react/button-has-type */
import React from "react";

function Buttons({
  specificButtonTitle,
  specificButtonStyling,
  specificButtonText,
  specificButtonIcon,
  specificButtonClick,
}) {
  return (
    <div>
      <button
        onClick={specificButtonClick}
        type="button"
        title={specificButtonTitle}
        className={`${specificButtonStyling} flex items-center justify-between`}
      >
        <p className="mr-2">{specificButtonText}</p>
        {specificButtonIcon}
      </button>
    </div>
  );
}

export default Buttons;
