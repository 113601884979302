import React from "react";
import { useNavigate } from "react-router-dom";
import { NonAuthRoutes } from "../../helpers/urls";
import { useWindowResize } from "../../helpers/useWindowResize";
import { ReactComponent as SkateCityLogo } from "../../assets/svg/skate-city-logo.svg";

function Footer() {
  const { width } = useWindowResize();
  const navigate = useNavigate();

  return (
    <div>
      {width > 1060 ? (
        <div className="h-[321px] mt-[50px]">
          <div className="flex items-center justify-center">
            <div className="w-full h-[320px] flex justify-evenly">
              <div className="w-[326px]">
                <SkateCityLogo title="Skate City" className="h-14 mb-10" />
                <p className="font-Popp-insRegular text-[16px] leading-[24px]">
                  Stay in the loop with skating events, exclusive discounts and
                  updates on latest games and other activities
                </p>
              </div>

              <div>
                <p className="mb-3 font-PoppinsBold text-[25px] leading-[37px] text-skatecityng-purple">
                  Quick links
                </p>
                <nav>
                  <ol>
                    <li className="mb-4">
                      <button
                        type="button"
                        title="Home"
                        onClick={() => navigate(NonAuthRoutes.landingPage)}
                      >
                        <p className=" font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                          Home
                        </p>
                      </button>
                    </li>
                    <li className="mb-4">
                      <button
                        type="button"
                        title="Our Story"
                        onClick={() => navigate(NonAuthRoutes.ourStory)}
                      >
                        <p className=" font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                          Our Story
                        </p>
                      </button>
                    </li>
                    <li className="mb-4">
                      <button
                        type="button"
                        title="Play"
                        onClick={() => navigate(NonAuthRoutes.play)}
                      >
                        <p className=" font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                          Play
                        </p>
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        title="Eat"
                        onClick={() => navigate(NonAuthRoutes.eat)}
                      >
                        <p className=" font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                          Eat
                        </p>
                      </button>
                    </li>
                  </ol>
                </nav>
              </div>

              <div>
                <p className="mb-3 font-PoppinsBold text-[25px] leading-[37px] text-skatecityng-purple">
                  Support
                </p>
                <nav>
                  <ol>
                    <li className="mb-4">
                      <button
                        type="button"
                        title="Contact"
                        onClick={() => navigate(NonAuthRoutes.contact)}
                      >
                        <p className=" font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                          Contact
                        </p>
                      </button>
                    </li>
                    <li className="mb-4">
                      <button
                        type="button"
                        title="Membership"
                        onClick={() =>
                          navigate(NonAuthRoutes.play, {
                            state: { sectionName: "membership" },
                          })
                        }
                      >
                        <p className=" font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                          Membership
                        </p>
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        title="Partners"
                        onClick={() =>
                          navigate(NonAuthRoutes.ourStory, {
                            state: { sectionName: "partners" },
                          })
                        }
                      >
                        <p className=" font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                          Partners
                        </p>
                      </button>
                    </li>
                  </ol>
                </nav>
              </div>

              <div>
                <p className="mb-3 font-PoppinsBold text-[25px] leading-[37px] text-skatecityng-purple">
                  Opening Hours
                </p>
                <nav>
                  <ol>
                    <div className="flex items-center justify-between w-[302px]">
                      <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        Monday - Thursday
                      </li>
                      <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        9AM - 8PM
                      </li>
                    </div>

                    <div className="flex items-center justify-between w-[302px]">
                      <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        Friday - Saturday
                      </li>
                      <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        9AM - 9PM
                      </li>
                    </div>

                    <div className="flex items-center justify-between w-[302px]">
                      <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        Sun
                      </li>
                      <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        12PM - 8PM
                      </li>
                    </div>

                    <div className="flex items-center justify-between w-[302px]">
                      <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        Public Holidays
                      </li>
                      <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                        Open
                      </li>
                    </div>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="h-[1px]  mx-[110px] bg-[#3C6791] opacity-50" />
          <div className="h-[73px] flex items-center justify-center">
            <div>
              <p className="mb-4 mt-6 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-purple text-center ">
                © Copyright 2022 Blacksam. Trademarks and brands are the
                property of Blacksam Ltd
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-[1039px] mt-[50px]">
          <div className="flex items-center justify-center">
            <div className="w-full px-[10px] ">
              <div className="flex justify-center">
                <SkateCityLogo title="Skate City" className="h-14 mb-10" />
              </div>
              <p className="font-PoppinsRegular text-center text-[16px] leading-[24px]">
                Stay in the loop with skating events, exclusive discounts and
                updates on latest games and other activities
              </p>

              <div className="flex justify-center text-center">
                <div>
                  <p className="mb-2 mt-[64px] font-PoppinsBold text-[25px] leading-[37px] text-skatecityng-purple">
                    Quick links
                  </p>
                  <nav>
                    <ol>
                      <li className="mb-4">
                        <button
                          type="button"
                          title="Home"
                          onClick={() => navigate(NonAuthRoutes.landingPage)}
                        >
                          <p className=" font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                            Home
                          </p>
                        </button>
                      </li>

                      <li className="mb-4">
                        <button
                          type="button"
                          title="Our Story"
                          onClick={() => navigate(NonAuthRoutes.ourStory)}
                        >
                          <p className=" font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                            Our Story
                          </p>
                        </button>
                      </li>

                      <li className="mb-4">
                        <button
                          type="button"
                          title="Play"
                          onClick={() => navigate(NonAuthRoutes.play)}
                        >
                          <p className=" font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                            Play
                          </p>
                        </button>
                      </li>

                      <li className="mb-4">
                        <button
                          type="button"
                          title="Eat"
                          onClick={() => navigate(NonAuthRoutes.eat)}
                        >
                          <p className=" font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                            Eat
                          </p>
                        </button>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>

              <div className="mt-[64px] flex justify-center text-center">
                <div>
                  <p className="mb-2 font-PoppinsBold text-[25px] leading-[37px] text-skatecityng-purple">
                    Support
                  </p>
                  <nav>
                    <ol>
                      <li className="mb-4">
                        <button
                          type="button"
                          title="Contact"
                          onClick={() => navigate(NonAuthRoutes.contact)}
                        >
                          <p className=" font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                            Contact
                          </p>
                        </button>
                      </li>
                      <li className="mb-4">
                        <button
                          type="button"
                          title="Membership"
                          onClick={() =>
                            navigate(NonAuthRoutes.play, {
                              state: { sectionName: "membership" },
                            })
                          }
                        >
                          <p className=" font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                            Membership
                          </p>
                        </button>
                      </li>
                      <li className="mb-4">
                        <button
                          type="button"
                          title="Partners"
                          onClick={() =>
                            navigate(NonAuthRoutes.ourStory, {
                              state: { sectionName: "partners" },
                            })
                          }
                        >
                          <p className=" font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                            Partners
                          </p>
                        </button>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>

              <div className="mt-[64px] flex justify-center text-center">
                <div>
                  <p className="mb-2 font-PoppinsBold text-[25px] leading-[37px] text-skatecityng-purple">
                    Opening Hours
                  </p>
                  <nav>
                    <ol>
                      <div className="flex items-center justify-between w-[302px]">
                        <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                          Monday - Thursday
                        </li>
                        <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                          9AM - 8PM
                        </li>
                      </div>

                      <div className="flex items-center justify-between w-[302px]">
                        <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                          Friday - Saturday
                        </li>
                        <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                          9AM - 9PM
                        </li>
                      </div>

                      <div className="flex items-center justify-between w-[302px]">
                        <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                          Sun
                        </li>
                        <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                          12PM - 8PM
                        </li>
                      </div>

                      <div className="flex items-center justify-between w-[302px]">
                        <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                          Public Holidays
                        </li>
                        <li className="mb-4 font-PoppinsRegular text-[16px] leading-[24px] text-skatecityng-font-black">
                          Open
                        </li>
                      </div>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[1px] mt-[80px] mx-[20px] bg-[#3C6791] opacity-50" />
          <div className="h-[75px] flex items-center justify-center">
            <div>
              <p className="mb-4 mt-6 text-center px-[20px] font-PoppinsRegular text-[11px] leading-[16.5px] text-skatecityng-purple">
                © Copyright 2022 Blacksam. Trademarks and brands are the
                property of Blacksam Ltd
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
