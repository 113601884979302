import React from "react";
import { useNavigate } from "react-router-dom";
import { NonAuthRoutes } from "../../helpers/urls";
import { useWindowResize } from "../../helpers/useWindowResize";
import { ReactComponent as ArrowRightWhite } from "../../assets/svg/arrow-right-white.svg";
import RollerSkateShoeWhite from "../../assets/img/roller-skate-shoe-white.png";
import RollerSkateKneeGuard from "../../assets/img/roller-skate-knee-guard.png";
import RollerSkateSupportGuard from "../../assets/img/roller-skate-support-guard.png";

function Section2() {
  const { width } = useWindowResize();
  const navigate = useNavigate();

  return (
    <div>
      {width > 1060 ? (
        <div className="h-screen pb-[100px] ">
          <div className="flex items-center justify-center h-screen px-20">
            <div>
              <p className="mb-6 text-center w-full font-Spartan text-[42px] leading-[40px]">
                Glide into a world of fun at our skating rink
              </p>
              <p className="mb-6 text-center w-full font-PoppinsRegular text-xl leading-[32px]">
                Our rink offers top-notch facilities and a friendly atmosphere
                for skaters of all levels. Whether you&apos;re looking to
                improve your skills or just have some fun, our rink is the
                perfect place to be. Come roll with it!
              </p>
              <div className="mb-16 w-full flex items-center justify-center">
                <button
                  type="button"
                  title="Learn more about us"
                  onClick={() => navigate(NonAuthRoutes.ourStory)}
                  className="hover:bg-[#565bbf] w-[247px] h-[50px] bg-skatecityng-purple rounded-[100px] mt-4 flex items-center justify-center"
                >
                  <p className="text-white mr-2">Learn more about us</p>
                  <ArrowRightWhite />
                </button>
              </div>
              <div className="w-full flex items-center justify-between">
                <div className="w-[30%]">
                  <div className="w-full h-[300px] mb-6 rounded-lg shadow-box bg-white  flex items-center justify-center">
                    <img
                      src={RollerSkateShoeWhite}
                      alt="Book your rental Skating Shoes"
                    />
                  </div>
                  <div>
                    <p className="font-PoppinsMedium text-[20px] text-center leading-[28px] text-skatecityng-font-black">
                      Book your rental
                    </p>
                    <p className="font-PoppinsMedium text-[32px] text-center leading-[44.4px] text-skatecityng-font-black">
                      Skating Shoes
                    </p>
                  </div>
                </div>
                <div className="w-[30%]">
                  <div className="w-full h-[300px] mb-6 rounded-lg shadow-box bg-white  flex items-center justify-center">
                    <img
                      src={RollerSkateKneeGuard}
                      alt=" We provide free Protective Gear"
                    />
                  </div>
                  <div>
                    <p className="font-PoppinsMedium text-[20px] text-center leading-[28px] text-skatecityng-font-black">
                      We provide free
                    </p>
                    <p className="font-PoppinsMedium text-[32px] text-center leading-[44.4px] text-skatecityng-font-black">
                      Protective Gear
                    </p>
                  </div>
                </div>
                <div className="w-[30%]">
                  <div className="w-full h-[300px] mb-6 rounded-lg shadow-box bg-white  flex items-center justify-center">
                    <img
                      src={RollerSkateSupportGuard}
                      alt="We offer Training Services"
                    />
                  </div>
                  <div>
                    <p className="font-PoppinsMedium text-[20px] text-center leading-[28px] text-skatecityng-font-black">
                      We offer
                    </p>
                    <p className="font-PoppinsMedium text-[32px] text-center leading-[44.4px] text-skatecityng-font-black">
                      Training Services
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className="flex items-center justify-center px-4">
            <div>
              <p className="mb-6 text-center w-full font-Spartan text-[20px] leading-[25px]">
                Glide into a world of fun at our skating rink
              </p>
              <p className="mb-6 text-center w-full font-PoppinsRegular text-sm leading-[23px]">
                Our rink offers top-notch facilities and a friendly atmosphere
                for skaters of all levels. Whether you&apos;re looking to
                improve your skills or just have some fun, our rink is the
                perfect place to be. Come roll with it!
              </p>
              <div className="mb-16 w-full flex items-center justify-center">
                <button
                  type="button"
                  title="Learn more about us"
                  onClick={() => navigate(NonAuthRoutes.ourStory)}
                  className="hover:bg-[#565bbf] w-[247px] h-[50px] bg-skatecityng-purple rounded-[100px] mt-4 flex items-center justify-center"
                >
                  <p className="text-white mr-2">Learn more about us</p>
                  <ArrowRightWhite />
                </button>
              </div>
              <div className="w-full flex flex-col items-center justify-center">
                <div className="w-[90%]">
                  <div className="w-full h-[300px] mb-6 rounded-lg shadow-box bg-white  flex items-center justify-center">
                    <img
                      src={RollerSkateShoeWhite}
                      alt="Book your rental Skating Shoes"
                    />
                  </div>
                  <div>
                    <p className="font-PoppinsMedium text-[13px] text-center leading-[15px] text-skatecityng-blue">
                      Book your rental
                    </p>
                    <p className="font-PoppinsMedium mt-2 text-[20px] text-center leading-[28px] text-skatecityng-font-black">
                      Skating Shoes
                    </p>
                  </div>
                </div>
                <div className="w-[90%]">
                  <div className="w-full h-[300px] mb-6 mt-10 rounded-lg shadow-box bg-white  flex items-center justify-center">
                    <img
                      src={RollerSkateKneeGuard}
                      alt="We provide free Protective Gear"
                    />
                  </div>
                  <div>
                    <p className="font-PoppinsMedium text-[13px] text-center leading-[15px] text-skatecityng-blue">
                      We provide free
                    </p>
                    <p className="font-PoppinsMedium mt-2 text-[20px] text-center leading-[28px] text-skatecityng-font-black">
                      Protective Gear
                    </p>
                  </div>
                </div>
                <div className="w-[90%]">
                  <div className="w-full h-[300px] mb-6 mt-10 rounded-lg shadow-box bg-white  flex items-center justify-center">
                    <img
                      src={RollerSkateSupportGuard}
                      alt="We offer Training Services"
                    />
                  </div>
                  <div>
                    <p className="font-PoppinsMedium text-[13px] text-center leading-[15px] text-skatecityng-blue">
                      We offer
                    </p>
                    <p className="font-PoppinsMedium mt-2 text-[20px] text-center leading-[28px] text-skatecityng-font-black">
                      Training Services
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Section2;
