import React from "react";
import { useNavigate } from "react-router-dom";
import { NonAuthRoutes } from "../../helpers/urls";
import { ReactComponent as ArrowRightWhite } from "../../assets/svg/arrow-right-white.svg";
import { ReactComponent as ArrowRightPurple } from "../../assets/svg/arrow-right-purple.svg";
import Playstation4Image from "../../assets/img/Playstation-4-Image.png";
import VR360Image from "../../assets/img/VR-360-Image.png";
import HoverboardImage from "../../assets/img/Hoverboard-Image.png";
import SkatingImage from "../../assets/img/Skating-Image.png";
import SnookerImage from "../../assets/img/Snooker-Image.png";
import { useWindowResize } from "../../helpers/useWindowResize";

function ActivitiesDesktopReusableComponent({
  image,
  cardName,
  cardSession,
  cardDetails,
  price,
  age,
  buttonText,
  specificStyle,
}) {
  const navigate = useNavigate();

  return (
    <div className={`${specificStyle} flex-none w-[420px] `}>
      <div className="h-[300px] mb-5 rounded-lg bg-white flex items-center justify-center">
        {image}
      </div>
      <div className="flex items-center justify-between">
        <p className="font-PoppinsSemiBold text-center text-[32px] leading-[44.8px] text-skatecityng-font-black">
          {cardName}
        </p>
        <button
          type="button"
          title={cardSession}
          className="h-[31px] px-3 bg-opacity-40 bg-[#C4D9EE] rounded-[100px] flex items-center justify-center border text-skatecityng-blue border-skatecityng-blue"
        >
          {cardSession}
        </button>
      </div>
      <div>
        <p className="font-PoppinsRegular text-[20px] leading-[28px] my-6  text-skatecityng-font-black">
          {cardDetails}
        </p>
      </div>
      <div className="flex items-center justify-between py-4">
        <div>
          <p className="font-PoppinsMedium text-[28px] leading-[39.2px] text-skatecityng-font-black">
            {price}
          </p>
          <p className="font-PoppinsRegular text-[14px] leading-[32px] text-skatecityng-blue">
            {age}
          </p>
        </div>
        <div className="flex items-center">
          <div>
            <button
              type="button"
              title={buttonText}
              onClick={() => navigate(NonAuthRoutes.contact)}
              className="hover:bg-[#565bbf] px-6 h-[50px] bg-skatecityng-purple rounded-[100px] flex items-center justify-center"
            >
              <p className="text-white mr-2">{buttonText}</p>
              <ArrowRightWhite />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function ActivitiesMobileReusableComponent({
  image,
  cardName,
  cardSession,
  cardDetails,
  price,
  age,
  buttonText,
  specificStyle,
}) {
  const navigate = useNavigate();

  return (
    <div className={`${specificStyle} flex-none w-[209px] `}>
      <div className="w-[209px] h-[149px] mb-5 rounded-lg bg-white flex items-center justify-center">
        {image}
      </div>
      <div className="flex items-center justify-between">
        <p className="font-PoppinsSemiBold text-center text-[16px] leading-[22px] text-skatecityng-font-black">
          {cardName}
        </p>
        <button
          type="button"
          title={cardSession}
          className="h-[16px] text-[6.49px] leading-[11.49px] px-3 bg-opacity-40 bg-[#C4D9EE] rounded-[100px] flex items-center justify-center border text-skatecityng-blue border-skatecityng-blue"
        >
          {cardSession}
        </button>
      </div>
      <div>
        <p className="font-PoppinsRegular text-[11px] leading-[18px] my-6  text-skatecityng-font-black">
          {cardDetails}
        </p>
      </div>
      <div className="flex items-center justify-between py-4">
        <div>
          <p className="font-PoppinsMedium text-[13.99px] leading-[19.58px] text-skatecityng-font-black">
            {price}
          </p>
          <p className="font-PoppinsRegular text-[7.99px] leading-[15.99px] text-skatecityng-blue">
            {age}
          </p>
        </div>
        <div className="flex items-center">
          <div>
            <button
              type="button"
              title={buttonText}
              onClick={() => navigate(NonAuthRoutes.play)}
              className="hover:bg-[#565bbf] px-3 py-4 h-[26px] bg-skatecityng-purple rounded-[100px] flex items-center justify-center"
            >
              <p className="text-white mr-2 text-[11px] leading-[15.4px]">
                {buttonText}
              </p>
              <ArrowRightWhite className="w-[12px]" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Section3() {
  const { width } = useWindowResize();
  const navigate = useNavigate();

  return (
    <div>
      {width > 1060 ? (
        <div className="w-full pt-[64px] mt-[100px] bg-[#E5EAF4]">
          <div className="px-20">
            <div>
              <p className="mb-6 text-center text-skatecityng-purple font-Spartan text-[32px] leading-[40px]">
                Level up your fun with our gaming activities – every play is a
                win
              </p>
              <p className="mb-6 text-center font-PoppinsRegular text-xl leading-[32px]">
                Don&apos;t wait another day to indulge your gaming passion -
                gamers from age 4+ and all skill levels are welcome to indulge
                in their passion. With our curation of the greatest games, we
                guarantee an unforgettable gaming experience at SkateCity.
              </p>
              <div className="mb-16 w-full flex items-center justify-center">
                <button
                  type="button"
                  title="See all activities"
                  onClick={() =>
                    navigate(NonAuthRoutes.play, {
                      state: { sectionName: "activities" },
                    })
                  }
                  className="hover:bg-white w-[210px] h-[50px] bg-[#D3D7E9] rounded-[100px] mt-4 flex items-center justify-center border border-skatecityng-purple"
                >
                  <p className="text-skatecityng-purple mr-2 text-base leading-6">
                    See all activities
                  </p>
                  <ArrowRightPurple />
                </button>
              </div>

              <div className="flex flex-nowrap overflow-x-auto ">
                <ActivitiesDesktopReusableComponent
                  image={<img src={Playstation4Image} alt="Playstation 4" />}
                  cardName="Playstation 4"
                  cardSession="1 session / game"
                  cardDetails="Fun and social way to try out new games or enjoy gaming with friends in
              a social setting."
                  price="₦500"
                  age="Age 4+"
                  buttonText="Book now"
                  specificStyle="mr-[64px]"
                />
                <ActivitiesDesktopReusableComponent
                  image={<img src={VR360Image} alt="VR 360" />}
                  cardName="VR 360"
                  cardSession="1 session"
                  cardDetails="Enter an immersive virtual experience, with 360-degree views and interactive gameplay."
                  price="₦2,000"
                  age="Age 4+"
                  buttonText="Book now"
                  specificStyle="mr-[64px]"
                />
                <ActivitiesDesktopReusableComponent
                  image={<img src={HoverboardImage} alt="Hoverboard" />}
                  cardName="Hoverboard"
                  cardSession="10 minutes"
                  cardDetails="Ride on a self-balancing hoverboard and perform exciting tricks and manoeuvres in a safe and controlled environment."
                  price="₦1,500"
                  age="Age 4+"
                  buttonText="Book now"
                  specificStyle="mr-[64px]"
                />
                <ActivitiesDesktopReusableComponent
                  image={<img src={SkatingImage} alt="Skating" />}
                  cardName="Skating"
                  cardSession="45 minutes"
                  cardDetails="Show off your skills on wheels in a safe and controlled environment. Perfect for experienced and beginners skaters."
                  price="₦3,000"
                  age="Age 4+"
                  buttonText="Book now"
                  specificStyle="mr-[64px]"
                />
                <ActivitiesDesktopReusableComponent
                  image={<img src={SnookerImage} alt="Snooker" />}
                  cardName="Snooker"
                  cardSession="1 game"
                  cardDetails="Strategise and aim to score more points than your opponent by pocketing coloured balls in a game of snooker."
                  price="₦1,000"
                  age="Age 4+"
                  buttonText="Book now"
                  specificStyle=""
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full pt-[40px] mt-[100px] bg-[#E5EAF4]">
          <div className="px-2">
            <div>
              <p className="mb-6 text-center text-skatecityng-purple font-Spartan text-[20px] leading-[25px]">
                Level up your fun with our gaming activities – every play is a
                win
              </p>
              <p className="mb-6 text-center font-PoppinsRegular text-xs leading-[23px] px-2">
                Don&apos;t wait another day to indulge your gaming passion -
                gamers from age 4+ and all skill levels are welcome to indulge
                in their passion. With our curation of the greatest games, we
                guarantee an unforgettable gaming experience at SkateCity.
              </p>
              <div className="mb-16 w-full flex items-center justify-center">
                <button
                  type="button"
                  title="See all activities"
                  onClick={() =>
                    navigate(NonAuthRoutes.play, {
                      state: { sectionName: "activities" },
                    })
                  }
                  className="hover:bg-white  h-[40px] px-4 bg-[#D3D7E9] rounded-[100px] mt-4 flex items-center justify-center border border-skatecityng-purple"
                >
                  <p className="text-skatecityng-purple mr-2 text-xs leading-6">
                    See all activities
                  </p>
                  <ArrowRightPurple />
                </button>
              </div>

              <div className="flex flex-nowrap overflow-x-auto px-4">
                <ActivitiesMobileReusableComponent
                  image={<img src={Playstation4Image} alt="Playstation 4" />}
                  cardName="Playstation 4"
                  cardSession="1 session / game"
                  cardDetails="Fun and social way to try out new games or enjoy gaming with friends in
            a social setting."
                  price="₦500"
                  age="Age 4+"
                  buttonText="Book now"
                  specificStyle="mr-[64px]"
                />
                <ActivitiesMobileReusableComponent
                  image={<img src={VR360Image} alt="VR 360" />}
                  cardName="VR 360"
                  cardSession="1 session"
                  cardDetails="Enter an immersive virtual experience, with 360-degree views and interactive gameplay."
                  price="₦2,000"
                  age="Age 4+"
                  buttonText="Book now"
                  specificStyle="mr-[64px]"
                />
                <ActivitiesMobileReusableComponent
                  image={<img src={HoverboardImage} alt="Hoverboard" />}
                  cardName="Hoverboard"
                  cardSession="10 minutes"
                  cardDetails="Ride on a self-balancing hoverboard and perform exciting tricks and manoeuvres in a safe and controlled environment."
                  price="₦1,500"
                  age="Age 4+"
                  buttonText="Book now"
                  specificStyle="mr-[64px]"
                />
                <ActivitiesMobileReusableComponent
                  image={<img src={SkatingImage} alt="Skating" />}
                  cardName="Skating"
                  cardSession="45 minutes"
                  cardDetails="Show off your skills on wheels in a safe and controlled environment. Perfect for experienced and beginners skaters."
                  price="₦3,000"
                  age="Age 4+"
                  buttonText="Book now"
                  specificStyle="mr-[64px]"
                />
                <ActivitiesMobileReusableComponent
                  image={<img src={SnookerImage} alt="Snooker" />}
                  cardName="Snooker"
                  cardSession="1 game"
                  cardDetails="Strategise and aim to score more points than your opponent by pocketing coloured balls in a game of snooker."
                  price="₦1,000"
                  age="Age 4+"
                  buttonText="Book now"
                  specificStyle=""
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Section3;
