/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import jsonp from "jsonp";
import queryString from "query-string";
import { useWindowResize } from "../../helpers/useWindowResize";
import Buttons from "../buttons/buttons";
import Inputs from "../inputs/inputs";
import { ReactComponent as ArrowRightPurple } from "../../assets/svg/arrow-right-purple.svg";
import { ReactComponent as LoadingIconPurple } from "../../assets/svg/loading-icon-purple.svg";

function EmailSubscribe({ setEmailResponseOpen, setIsErrorResponse }) {
  const { width } = useWindowResize();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [btnIsLoading, setBtnIsLoading] = useState(false);
  const [emptyInputWarning, setEmptyInputWarning] = useState(false);
  const [warning, setWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const subscribeToExclusiveOffers = (e) => {
    e.preventDefault();
    if (email.length === 0 || firstName.length === 0 || lastName.length === 0) {
      setEmptyInputWarning(true);
      setTimeout(() => {
        setEmptyInputWarning(false);
      }, 3000);
    } else {
      setBtnIsLoading(true);
      const formData = {
        EMAIL: email,
        FNAME: firstName,
        LNAME: lastName,
      };
      jsonp(
        `https://skatecityng.us14.list-manage.com/subscribe/post-json?u=0c39c72ec1b2cea6c7c70d9c5&amp;id=5b1d1935c8&${queryString.stringify(
          formData
        )}`,
        { param: "c" },
        (err, data) => {
          console.log("data:", data.result);
          console.log("data:", data.msg);
          if (data.result === "success") {
            setBtnIsLoading(false);
            setSuccess(true);
            setSuccessMessage(data.msg);
            setEmailResponseOpen(true);
            setIsErrorResponse(false);
            setTimeout(() => {
              setEmail("");
              setFirstName("");
              setLastName("");
              setSuccess(false);
              setSuccessMessage("");
            }, 10000);
          } else {
            setWarning(true);
            setWarningMessage(data.msg);
            setEmailResponseOpen(true);
            setIsErrorResponse(true);
            setBtnIsLoading(false);
            setTimeout(() => {
              setWarning(false);
              setWarningMessage("");
            }, 10000);
          }
        }
      );
    }
  };

  /** displays Warning */
  const displayEmptyInputWarningDesktop = () => {
    return (
      <div className="flex justify-center items-center">
        <p className="mt-6 mb-4 px-4 py-2 w-1/2 rounded font-PoppinsLight text-xs text-center text-red-500 border-2 border-red-400 bg-red-100">
          All entries and required to subscribe to exclusice offers...
        </p>
      </div>
    );
  };

  /** displays Warning */
  const displayEmptyInputWarningMobile = () => {
    return (
      <div className="flex justify-center items-center px-4">
        <p className="-mt-4 mb-4 px-4 py-2 w-[200px] rounded font-PoppinsLight text-xs text-center text-red-500 border-2 border-red-400 bg-red-100">
          All entries and required to subscribe to exclusice offers...
        </p>
      </div>
    );
  };

  /** displays Success */
  const displaySuccessDesktop = () => {
    return (
      <div className="flex justify-center items-center">
        <p className="mt-6 mb-4 px-4 py-2 w-1/2 rounded font-PoppinsLight text-xs text-center border-2 text-green-500  border-green-500 bg-green-100">
          {successMessage}
        </p>
      </div>
    );
  };

  /** displays Success */
  const displaySuccessMobile = () => {
    return (
      <div className="flex justify-center items-center px-4">
        <p className="-mt-4 mb-4 px-4 py-2 w-[200px] rounded font-PoppinsLight text-xs text-center border-2 text-green-500  border-green-500 bg-green-100">
          {successMessage}
        </p>
      </div>
    );
  };

  /** displays Warning */
  const displayWarningDesktop = () => {
    return (
      <div className="flex justify-center items-center">
        <p className="mt-6 mb-4 px-4 py-2 w-1/2 rounded font-PoppinsLight text-xs text-center text-red-500 border-2 border-red-400 bg-red-100">
          {warningMessage}
        </p>
      </div>
    );
  };

  /** displays Warning */
  const displayWarningMobile = () => {
    return (
      <div className="flex justify-center items-center px-4">
        <p className="-mt-4 mb-4 px-4 py-2 w-[200px] rounded font-PoppinsLight text-xs text-center text-red-500 border-2 border-red-400 bg-red-100">
          {warningMessage}
        </p>
      </div>
    );
  };

  return (
    <div>
      {width > 1060 ? (
        <div className="w-full py-[64px] mt-[100px] bg-skatecityng-purple">
          <div className="px-20">
            <div>
              <p className="mb-6 text-center text-white font-Spartan text-[32px] leading-[40px]">
                Sign Up for Exclusive Offers!
              </p>
              <p className="mb-6 mt-6 text-center text-white font-PoppinsRegular text-[20px] leading-[32px] px-[10px]">
                Stay in the loop with skating events, exclusive discounts and
                updates on latest games and other activities. You&apos;ll be the
                first to know about our newest attractions and games, don&apos;t
                miss out on these exciting opportunities - sign up now!
              </p>
            </div>
            <div className="mt-[64px]">
              <form className="flex items-center justify-evenly w-full">
                <Inputs
                  specificInputLabel="firstName"
                  specificInputType="text"
                  specificInputPlaceholder="Enter your first name"
                  specificInputValue={firstName}
                  specificInputOnChange={(e) => setFirstName(e.target.value)}
                  specificInputStyling="rounded-[200px] w-[282px] h-[47px] bg-skatecityng-purple text-white mr-6 placeholder-[#A8A9D9] border border-white "
                />
                <Inputs
                  specificInputLabel="lasttName"
                  specificInputType="text"
                  specificInputPlaceholder="Enter your last name"
                  specificInputValue={lastName}
                  specificInputOnChange={(e) => setLastName(e.target.value)}
                  specificInputStyling="rounded-[200px] w-[282px] h-[47px] bg-skatecityng-purple text-white mr-6 placeholder-[#A8A9D9] border border-white "
                />
                <Inputs
                  specificInputLabel="email"
                  specificInputType="email"
                  specificInputPlaceholder="Enter your email"
                  specificInputValue={email}
                  specificInputOnChange={(e) => setEmail(e.target.value)}
                  specificInputStyling="rounded-[200px] w-[432px] h-[47px] bg-skatecityng-purple text-white mr-6 placeholder-[#A8A9D9] border border-white "
                />
                {btnIsLoading ? (
                  <Buttons
                    specificButtonStyling="px-6 h-[50px] text-[16px] leading-[26px] text-skatecityng-purple bg-white hover:bg-[#ebecf4] rounded-[100px]"
                    specificButtonTitle="Loading"
                    specificButtonIcon={
                      <LoadingIconPurple className="spinning" />
                    }
                  />
                ) : (
                  <Buttons
                    specificButtonClick={(e) => subscribeToExclusiveOffers(e)}
                    specificButtonStyling="px-6 h-[50px] text-[16px] leading-[26px] text-skatecityng-purple bg-white hover:bg-[#ebecf4] rounded-[100px]"
                    specificButtonTitle="submit"
                    specificButtonText="Submit"
                    specificButtonIcon={<ArrowRightPurple />}
                  />
                )}
              </form>
              {emptyInputWarning ? displayEmptyInputWarningDesktop() : <span />}
              {warning ? displayWarningDesktop() : <span />}
              {success ? displaySuccessDesktop() : <span />}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full py-[64px] mt-[100px] bg-skatecityng-purple">
          <div className="px-2">
            <div>
              <p className="mb-6 text-center text-white font-Spartan text-[20px] leading-[25px]">
                Sign Up for Exclusive Offers!
              </p>
              <p className="mb-6 text-center text-white font-PoppinsRegular text-[14px] leading-[23px]">
                Stay in the loop with skating events, exclusive discounts and
                updates on latest games and other activities. You&apos;ll be the
                first to know about our newest attractions and games, don&apos;t
                miss out on these exciting opportunities - sign up now!
              </p>
            </div>
            <div className="mt-[64px] flex items-center justify-center w-full">
              <form>
                <Inputs
                  specificInputLabel="firstName"
                  specificInputType="text"
                  specificInputPlaceholder="Enter your first name"
                  specificInputValue={firstName}
                  specificInputOnChange={(e) => setFirstName(e.target.value)}
                  specificInputStyling="rounded-[200px] w-[282px] h-[47px] bg-skatecityng-purple text-white mb-6 placeholder-[#A8A9D9] border border-white "
                />
                <Inputs
                  specificInputLabel="lasttName"
                  specificInputType="text"
                  specificInputPlaceholder="Enter your last name"
                  specificInputValue={lastName}
                  specificInputOnChange={(e) => setLastName(e.target.value)}
                  specificInputStyling="rounded-[200px] w-[282px] h-[47px] bg-skatecityng-purple text-white mb-6 placeholder-[#A8A9D9 border border-white "
                />
                <Inputs
                  specificInputLabel="email"
                  specificInputType="email"
                  specificInputPlaceholder="Enter your email"
                  specificInputValue={email}
                  specificInputOnChange={(e) => setEmail(e.target.value)}
                  specificInputStyling="rounded-[200px] w-[282px] h-[47px] bg-skatecityng-purple text-white mb-6 placeholder-[#A8A9D9] border border-white "
                />
                {emptyInputWarning ? (
                  displayEmptyInputWarningMobile()
                ) : (
                  <span />
                )}
                {warning ? displayWarningMobile() : <span />}
                {success ? displaySuccessMobile() : <span />}
                <div className="flex items-center justify-center w-full">
                  {btnIsLoading ? (
                    <Buttons
                      specificButtonStyling="px-6 h-[50px] text-[16px] leading-[26px] text-skatecityng-purple bg-white hover:bg-[#ebecf4] rounded-[100px]"
                      specificButtonTitle="Loading"
                      specificButtonIcon={
                        <LoadingIconPurple className="spinning" />
                      }
                    />
                  ) : (
                    <Buttons
                      specificButtonClick={(e) => subscribeToExclusiveOffers(e)}
                      specificButtonStyling="px-6 h-[50px] text-[16px] leading-[26px] text-skatecityng-purple bg-white hover:bg-[#ebecf4] rounded-[100px]"
                      specificButtonTitle="submit"
                      specificButtonText="Submit"
                      specificButtonIcon={<ArrowRightPurple />}
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EmailSubscribe;
