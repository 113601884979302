import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { NonAuthRoutes } from "./urls";
import { ReactComponent as SkateCityLogo } from "../assets/svg/skate-city-logo.svg";
import LandingPage from "../pages/landingPage/landingPage";

const LazyOurStory = React.lazy(() => import("../pages/ourStory/ourStory"));
const LazyPlay = React.lazy(() => import("../pages/play/play"));
const LazyEat = React.lazy(() => import("../pages/eat/eat"));
const LazyContact = React.lazy(() => import("../pages/contact/contact"));

function Routers() {
  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-screen">
          <SkateCityLogo className="spinning" />
        </div>
      }
    >
      <Routes>
        <Route
          exact
          path={NonAuthRoutes.landingPage}
          element={<LandingPage />}
        />
        <Route path={NonAuthRoutes.ourStory} element={<LazyOurStory />} />
        <Route path={NonAuthRoutes.play} element={<LazyPlay />} />
        <Route path={NonAuthRoutes.eat} element={<LazyEat />} />
        <Route path={NonAuthRoutes.contact} element={<LazyContact />} />
      </Routes>
    </Suspense>
  );
}
export default Routers;
